var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3e9705560a2ec0340e0931233c913989d1d42ef0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { extraErrorDataIntegration } from '@sentry/integrations';

import('@sentry/nextjs').then((Sentry) =>
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    sampleRate: 0.1,
    tracesSampleRate: 0.01,
    normalizeDepth: 6,
    integrations: [
      extraErrorDataIntegration({
        depth: 5, // NOTE: normalizeDepthよりも小さい値とする必要あり
      }),
    ],
    ignoreErrors: [
      'hydrating',
      'Hydration',
      'server-rendered HTML',
      'Failed to fetch',
      'Cancel rendering route',
      'Non-Error exception captured', // NOTE: https://github.com/getsentry/sentry-javascript/issues/2292
    ],
  })
);
